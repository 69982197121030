import * as d3 from "d3"
import D3AverageRangeBarBase from './d3_average_range_bar_base'

export default class D3AverageRangeBarMulti extends D3AverageRangeBarBase {

  _loadMargins() {
    this.margins = {}
    this.margins.chart = {top: 30, right: 60, bottom: 30, left: 0}
    this.margins.footer = {top: 0, right: 60, bottom: 0, left: 0}
  }

  _loadColors() {
    this.colors = this.opts.colors || ['#FC8D59', '#FEE08B', '#99D594']
  }

  _drawBackgroundBars(chart, index) {
    const even = index%2 === 0
    const backgroundBars = chart.container.append('g')
      .attr('class', 'background-bars')
      .attr('transform', (even ? '' : 'translate(0, -'+(chart.scale.y.bandwidth())+')'))
    backgroundBars.selectAll('rect')
      .data(chart.data)
      .enter()
      .append('rect')
        .attr('x', (d) => chart.scale.x(0))
        .attr('y', (d) => chart.scale.y(d.id))
        .attr('width', (d) => chart.scale.x(5)-chart.scale.x(0))
        .attr('height', (d) => chart.scale.y.bandwidth())
    if(!even) {
      const last = chart.data[chart.data.length-1]
      backgroundBars.append('rect')
        .attr('x', chart.scale.x(0))
        .attr('y', chart.scale.y(last.id)+chart.scale.y.bandwidth()+(chart.scale.y.bandwidth()))
        .attr('width', (d) => chart.scale.x(5)-chart.scale.x(0))
        .attr('height', (d) => chart.scale.y.bandwidth())
    }
  }

  draw() {
    this.charts.forEach((d, i) => {
      this._drawGraph(d)
      d.container = d.svg.append('g')
        .attr('class', 'd3-chart')
        .attr('transform', d.dimensions.chart.transform)
      this._drawBackgroundBars(d, i)
      this._drawBars(d)
      this._drawMinMaxText(d, 'min')
      this._drawMinMaxText(d, 'max')
      d.container.append('g').attr('class', 'averages')
      this._drawAverages(d)
      this._drawAveragesText(d)
    })
    this._drawFooter()
  }

}
