import * as d3 from "d3";

export default class D3StackedBarChart {
  constructor(containerSelector, data, opts = {}) {
    this.container = d3.select(containerSelector);
    this.data = data;
    this._loadStackedData();
    this.opts = opts;
    this.margins = this.opts.margins || { left: 115, top: 0, right: 0, bottom: 0 };
    this.colors = this.opts.colors || [
      "#CDCCCD",
      "#D53E4F",
      "#FC8D59",
      "#FEE08B",
      "#99D594",
      "#3288BD",
    ];
    this.svg = this.container.selectAll("svg");
    this._loadDimensions();
    this._loadRange();
    this._loadDomain();
    this._loadScale();
  }

  _loadStackedData() {
    this.stackedData = d3.stack().keys(this.data.scores.map((d) => d.key))(this.data.rows);
  }

  _loadBox() {
    if (window.forPrint) {
      // TODO: we will need to figure out some values here for print
      return { width: 950, height: 51 };
    } else {
      return this.svg.node().getBoundingClientRect();
    }
  }

  _loadDimensions() {
    const boundingBox = this._loadBox();
    this.dimensions = {
      chart: {
        width: boundingBox.width - this.margins.left - this.margins.right,
        height: boundingBox.height - this.margins.top - this.margins.bottom,
        transform: "translate(" + this.margins.left + ", " + this.margins.top + ")",
      },
      svg: {
        width: boundingBox.width,
        height: boundingBox.height,
      },
    };
  }

  _loadRange() {
    this.range = {
      x: [0, this.dimensions.chart.width],
      y: [0, this.dimensions.chart.height],
      z: this.colors,
    };
  }

  _loadDomain() {
    this.domain = {
      x: [0, 100],
      y: d3.nest().key((d) => d.indicator_id).entries(this.data.rows).map((d) => d.key),
      z: this.data.scores.map((d) => d.key),
    };
  }

  _loadScale() {
    this.scale = {
      x: d3.scaleLinear().domain(this.domain.x).range(this.range.x),
      y: d3.scaleBand().domain(this.domain.y).range(this.range.y).paddingInner(0.1).paddingOuter(0),
      z: d3.scaleOrdinal().domain(this.domain.z).range(this.range.z),
    };
  }

  _drawChart() {
    this.chart = this.svg
      .append("g")
      .attr("class", "d3-chart")
      .attr("transform", this.dimensions.chart.transform);
  }

  _drawBars() {
    const scoreGroup = this.chart
      .selectAll("g.score-group")
      .data(this.stackedData)
      .enter()
      .append("g")
      .attr("class", (d, i) => "score-group score-group-" + d.key)
      .attr("fill", (d) => this.scale.z(d.key));
    scoreGroup
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (d) => this.scale.x(d[0]))
      .attr("y", (d) => this.scale.y(d.data.indicator_id))
      .attr("width", (d) => this.scale.x(d[1]) - this.scale.x(d[0]))
      .attr("height", (d) => this.scale.y.bandwidth());
    scoreGroup
      .selectAll("text")
      .data((d) => d)
      .enter()
      .append("text")
      .attr("x", (d) => {
        const width = this.scale.x(d[1]) - this.scale.x(d[0]);
        const half = width == 0 ? 0 : width / 2;
        return this.scale.x(d[0]) + half;
      })
      .attr("y", (d) => this.scale.y(d.data.indicator_id) + this.scale.y.bandwidth() / 2)
      .attr("text-anchor", "middle")
      .selectAll("tspan")
      .data((d) => [d])
      .enter()
      .append("tspan")
      .attr("dominant-baseline", "middle")
      .text((d) => {
        const value = d[1] - d[0];
        return value > 2 ? value + "%" : null;
      })
      .attr("fill", "black");
  }

  _drawYAxis() {
    this.chart
      .append("g")
      .attr("class", "y-axis-label")
      .selectAll("text")
      .data(this.data.rows)
      .enter()
      .append("text")
      .attr("x", (d) => -10)
      .attr("y", (d) => this.scale.y(d.indicator_id) + this.scale.y.bandwidth() / 2)
      .attr("text-anchor", "end")
      .selectAll("tspan")
      .data((d) => [d])
      .enter()
      .append("tspan")
      .attr("dominant-baseline", "middle")
      .text((d) => "Indicator #" + d.indicator_id);
  }

  _drawKey() {
    const keys = this.container
      .selectAll(".report-frequency-count__summary-keys")
      .style("margin-left", this.margins.left + "px")
      .selectAll("div")
      .data(this.data.scores)
      .enter()
      .append("div")
      .attr("class", "d-flex pe-4 align-items-center gap-2");
    keys
      .append("div")
      .attr("class", "key me-2")
      .style("background-color", (d) => this.scale.z(d.key));
    keys
      .append("span")
      .attr("class", "gap-2")
      .attr("aria-hidden", true)
      .text((d) => d.label);
  }

  draw() {
    this._drawChart();
    this._drawBars();
    this._drawYAxis();
    this._drawKey();
    if (!window.forPrint) {
      this.container
        .selectAll(".chart-tooltip")
        .data(this.data.rows)
        .enter()
        .append("div")
        .attr("class", "chart-tooltip d-flex align-items-center")
        .html((d) => "<span>Click to see details for indicator #" + d.indicator_id + "</span>")
        .style("height", this.scale.y.bandwidth() + "px")
        .style("left", this.margins.left + "px")
        .style("right", this.margins.right + "px")
        .style("bottom", (this.dimensions.chart.height - this.scale.y.bandwidth()) / 2 + "px");
    }
  }
}
