import * as d3 from "d3"

export default class D3HorizontalBarChart {

  constructor(containerSelector, data, opts={}) {
    this.container = d3.select(containerSelector)
    this.data = data
    this.opts = opts
    this.margins = this.opts.margins || {left: 140, top: 0, right: 20, bottom: 60}
    this.colors = this.opts.colors || ['#3288BD']
    this.svg = this.container.selectAll('svg')
    this._loadDimensions()
    this._loadRange()
    this._loadDomain()
    this._loadScale()
  }

  _loadBox() {
    if(window.forPrint) {
      let boxHeight = $(this.container.node()).data('box-height')
      return {width: 950, height: (boxHeight || 350)}
    } else {
      return this.svg.node().getBoundingClientRect()
    }
  }

  _loadDimensions() {
    const boundingBox = this._loadBox()
    this.dimensions = {
      chart: {
        width: boundingBox.width - this.margins.left - this.margins.right,
        height: boundingBox.height - this.margins.top - this.margins.bottom,
        transform: "translate("+this.margins.left +", "+this.margins.top+")"
      },
      svg: {
        width: boundingBox.width,
        height: boundingBox.height
      }
    }
  }

  _loadRange() {
    this.range = {
      x: [0, this.dimensions.chart.width],
      y: [0, this.dimensions.chart.height],
      z: this.colors
    }
  }

  _loadDomain() {
    this.domain = {
      x: [0, 100],
      y: this.data.scores.map((d) => d.label),
      z: [1]
    }
  }

  _loadScale() {
    this.scale = {
      x: d3.scaleLinear().domain(this.domain.x).range(this.range.x),
      y: d3.scaleBand().domain(this.domain.y).range(this.range.y).paddingInner(0.5).paddingOuter(0.25),
      z: d3.scaleOrdinal().domain(this.domain.z).range(this.range.z)
    }
  }

  _drawGraph() {
    const dataX = d3.range(this.domain.x[0], this.domain.x[1], 10).concat(this.domain.x[1])
    const dataY = [0]
    this.chart.append('g')
      .attr('class', 'graph-lines vertical')
      .selectAll('line')
      .data(dataX)
      .enter()
      .append('line')
        .attr('x1', (d) => this.scale.x(d))
        .attr('y1', 0)
        .attr('x2', (d) => this.scale.x(d))
        .attr('y2', this.dimensions.chart.height)
        
    this.chart.append('g')
      .attr('class', 'graph-lines horizontal')
      .selectAll('line')
      .data(dataY)
      .enter()
      .append('line')
        .attr('x1', this.scale.x(0))
        .attr('y1', this.dimensions.chart.height)
        .attr('x2', this.dimensions.svg.width)
        .attr('y2', this.dimensions.chart.height)
  }

  _drawChart() {
    this.svg.append('text')
      .attr('x', 0)
      .attr('y', 0)
      .attr('alignment-baseline', 'hanging')
      .attr('class', 'y-axis-labels__label')
      .attr('transform', `rotate(-90) translate(${((this.dimensions.chart.height/2)-10)*-1}, 14)`)
      .attr('text-anchor', 'middle')
      .text('Responses')

    this.svg.append('text')
      .attr('x', this.dimensions.chart.width/2)
      .attr('y', this.dimensions.svg.height - 10)
      .attr('text-anchor', 'middle')
      .attr('class', 'x-axis-labels__label')
      .attr('transform', `translate(${this.margins.left}, 0)`)
      .text("Percentage of responses")

    this.chart = this.svg.append('g')
      .attr('class', 'd3-chart')
      .attr('transform', this.dimensions.chart.transform)
  }

  _drawBars() {
    this.chart.append('g')
      .attr('class', 'bars')
      .attr('fill', this.scale.z(0))
      .selectAll('rect')
        .data(this.data.bars)
        .enter()
        .append('rect')
          .attr('x', (d) => this.scale.x(0))
          .attr('y', (d) => this.scale.y(d.label))
          .attr('width', (d) => this.scale.x(d.percentage))
          .attr('height', (d) => this.scale.y.bandwidth())

    this.chart.append('g')
      .attr('class', 'bar-labels')
      .selectAll('text')
        .data(this.data.bars)
        .enter()
        .append('text')
          .attr('x', (d) => this.scale.x(d.percentage)+5)
          .attr('y', (d) => this.scale.y(d.label)+(this.scale.y.bandwidth()/2))
          .attr('alignment-baseline', 'middle')
          .text((d) => `${d.percentage}% (${d.count})`)
  }

  _drawYAxis() {
    this.chart.append('g')
      .attr('class', 'y-axis-labels')
      .selectAll('g.y-axis-label')
        .data(this.data.scores)
        .enter()
        .append('g')
          .attr('class', 'y-axis-label')
          .selectAll('text')
            .data((d) => {
              let data = [{type: 'label', band: d.label, value: d.label}]
              let bar = this.data.bars.filter((x) => x.label == d.label)[0]
              if(bar) {
                data.push({type: 'value', band: bar.label, value: bar.percentage+'% ('+bar.count+')'})
              } else {
                data.push({type: 'value', band: d.label, value: '0% (0)'})
              }
              return data
            })
            .enter()
            .append('text')
              .attr('class', (d) => d.type)
              .attr('x', (d) => this.scale.x(0)-5)
              .attr('y', (d) => d.type == 'label' ? this.scale.y(d.band)+5 : this.scale.y(d.band)+this.scale.y.bandwidth())
              .attr('text-anchor', 'end')
              .text((d) => d.value)
  }

  _drawXAxis() {
    const xAxis = this.chart.append('g')
      .attr('class', 'x-axis-labels')
      .attr('transform', 'translate(0,'+this.dimensions.chart.height+')')
    const dataX = d3.range(this.domain.x[0], this.domain.x[1], 10).concat(this.domain.x[1])
    xAxis.selectAll('text')
      .data(dataX)
      .enter()
      .append('text')
        .attr('x', (d) => this.scale.x(d))
        .attr('y', (d) => 0)
        .attr('transform', 'translate(0, 17)')
        .attr('text-anchor', 'middle')
        .text((d) => d == 0 ? d : d+'%')
  }

  draw() {
    this._drawChart()
    this._drawGraph()
    this._drawBars()
    this._drawYAxis()
    this._drawXAxis()
  }

}
