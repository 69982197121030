import * as d3 from "d3";

export default class D3AverageRangeBarBase {
  constructor(containerSelector, data, opts = {}) {
    this.container = d3.select(containerSelector);
    this.data = data;
    this.opts = opts;
    this._loadMargins();
    this._loadColors();
    this._loadCharts();
    this._loadFooter();
    this._loadCompareToggle();
  }

  _loadCompareToggle() {
    this.compare = this.opts.compare || false;
    if (this.opts.toggleSelector) {
      const compareToggle = $(this.opts.toggleSelector);
      const legendImage = $(this.opts.legendImageSelector);
      this.compare = compareToggle.is(":checked");
      compareToggle.on("change", (e) => {
        this.compare = compareToggle.is(":checked");
        if (this.compare) {
          legendImage.find(".with").show();
          legendImage.find(".without").hide();
        } else {
          legendImage.find(".with").hide();
          legendImage.find(".without").show();
        }
        this.charts.forEach((d) => {
          this._drawAverages(d);
          this._drawAveragesText(d);
        });
      });
    }
  }

  _loadDimensions(boundingBox, margins) {
    return {
      chart: {
        width: boundingBox.width - margins.left - margins.right,
        height: boundingBox.height - margins.top - margins.bottom,
        transform: "translate(" + margins.left + ", " + margins.top + ")",
      },
      svg: {
        width: boundingBox.width,
        height: boundingBox.height,
      },
    };
  }

  _loadRange(dimensions) {
    return {
      x: [0, dimensions.chart.width],
      y: [0, dimensions.chart.height],
      z: this.colors,
    };
  }

  _loadDomain(yData) {
    const scores = this.data.scores.map((d) => d.value);
    let allBars = d3.merge(this.data.rows.map((d) => d.bars));
    let colorDomain = d3.nest()
      .key((d) => d.id)
      .entries(allBars)
      .map((d) => d.key);

    return {
      x: [d3.min(scores), d3.max(scores)],
      y: yData.map((d) => d.id),
      z: colorDomain,
    };
  }

  _loadScale(domain, range) {
    return {
      x: d3.scaleLinear().domain(domain.x).range(range.x),
      y: d3.scaleBand().domain(domain.y).range(range.y).paddingInner(0.5).paddingOuter(0),
      z: d3.scaleOrdinal().domain(domain.z).range(range.z),
    };
  }

  _loadBox(svg, chart = false) {
    if (window.forPrint) {
      let dataBox = svg.attr("data-box");
      dataBox = JSON.parse(dataBox);
      return dataBox;
    } else {
      return svg.node().getBoundingClientRect();
    }
  }

  _loadFooter() {
    const svg = this.container.select("tfoot svg");
    const box = this._loadBox(svg);
    const dimensions = this._loadDimensions(box, this.margins.footer);
    const range = this._loadRange(dimensions, []);
    const domain = this._loadDomain([]);
    const scale = this._loadScale(domain, range);
    this.footer = {
      svg: svg,
      data: this.data.scores,
      dimensions: dimensions,
      range: range,
      domain: domain,
      scale: scale,
    };
  }

  _loadCharts() {
    this.charts = this.data.rows.map((d) => {
      const svg = this.container.select('[data-row="' + d.title + '"] svg');
      const box = this._loadBox(svg, d);
      const dimensions = this._loadDimensions(box, this.margins.chart);
      const range = this._loadRange(dimensions);
      const domain = this._loadDomain(d.bars);
      const scale = this._loadScale(domain, range);
      return {
        svg: svg,
        data: d.bars,
        dimensions: dimensions,
        range: range,
        domain: domain,
        scale: scale,
      };
    });
  }

  _drawGraph(chart) {
    chart.svg
      .append("g")
      .attr("class", "graph-lines vertical")
      .selectAll("line")
      .data(this.data.scores)
      .enter()
      .append("line")
      .attr("x1", (d) => chart.scale.x(d.value))
      .attr("y1", 0)
      .attr("x2", (d) => chart.scale.x(d.value))
      .attr("y2", chart.dimensions.svg.height);

    chart.svg
      .append("g")
      .attr("class", "graph-lines horizontal")
      .selectAll("line")
      .data([1])
      .enter()
      .append("line")
      .attr("x1", chart.scale.x(chart.domain.x[0]))
      .attr("y1", 0)
      .attr("x2", chart.scale.x(chart.domain.x[1]))
      .attr("y2", 0);
  }

  _drawBars(chart) {
    const bars = chart.container.selectAll("rect.average-range-bar").data(chart.data);
    const enter = bars.enter();
    enter
      .append("rect")
      .attr("class", "average-range-bar")
      .attr("x", (n) => chart.scale.x(n.min))
      .attr("y", (n) => chart.scale.y(n.id))
      .attr("width", (n) => chart.scale.x(n.max) - chart.scale.x(n.min))
      .attr("height", (n) => chart.scale.y.bandwidth())
      .attr("fill", (n) => chart.scale.z(n.id));
  }

  _drawMinMaxText(chart, type) {
    chart.container
      .append("g")
      .attr("class", "range-" + type)
      .selectAll("text")
      .data(chart.data)
      .enter()
      .append("text")
      .attr("x", (d) => (type === "min" ? chart.scale.x(d.min) - 5 : chart.scale.x(d.max) + 5))
      .attr("y", (d) => chart.scale.y(d.id) + chart.scale.y.bandwidth() / 2)
      .style("font-size", "14px")
      .selectAll("tspan")
      .data((d) => [d])
      .enter()
      .append("tspan")
      .text((d) => d[type]);
  }

  _drawAverages(chart) {
    const offset = 5;
    let data = { primary: [], comparison: [] };
    chart.data.forEach((d) => {
      data.primary.push({ id: d.id, type: "primary", value: d.average });
    });
    if (this.compare) {
      chart.data.forEach((d) => {
        // prevent bar showing at zero for null values
        if (d.comparison_average) {
          data.comparison.push({ id: d.id, type: "comparison", value: d.comparison_average });
        }
      });
    }

    let primary = chart.container.selectAll("g.averages").selectAll("rect").data(data.primary);

    primary
      .enter()
      .append("rect")
      .attr("class", (d) => d.type + "-" + d.id + " " + d.type)
      .attr("x", (d) => chart.scale.x(d.value) - offset / 2)
      .attr("y", (d) => chart.scale.y(d.id))
      .attr("width", (d) => offset)
      .attr("height", (d) => chart.scale.y.bandwidth());

    primary.exit().remove();

    let comparison = chart.container
      .selectAll("g.averages")
      .selectAll("line")
      .data(data.comparison);

    comparison
      .enter()
      .append("line")
      .attr("class", (d) => d.type + "-" + d.id + " " + d.type)
      .attr("x1", (d) => chart.scale.x(d.value))
      .attr("y1", (d) => chart.scale.y(d.id))
      .attr("x2", (d) => chart.scale.x(d.value))
      .attr("y2", (d) => chart.scale.y(d.id) + chart.scale.y.bandwidth());

    comparison.exit().remove();
  }

  _drawAveragesText(chart) {
    const xOffset = 5;
    const yOffset = 2;
    let data = [];
    chart.data.forEach((d) => {
      data.push({
        id: d.id,
        type: "primary",
        value: d.average,
        position: d.average <= d.comparison_average ? "left" : "right",
      });
    });
    if (this.compare) {
      chart.data.forEach((d) => {
        data.push({
          id: d.id,
          type: "comparison",
          value: d.comparison_average,
          position: d.average > d.comparison_average ? "left" : "right",
        });
      });
    }
    let text = chart.container.selectAll("g.averages").selectAll("text").data(data);
    text
      .enter()
      .append("text")
      .attr("class", (d) => d.type + "-" + d.id)
      .attr("x", (d) => {
        const x = chart.scale.x(d.value);
        return d.position == "left" ? x - xOffset : x + xOffset;
      })
      .attr("y", (d) => chart.scale.y(d.id) - yOffset)
      .attr("text-anchor", (d) => (d.position == "left" ? "end" : "start"))
      .selectAll("tspan")
      .data((d) => [d])
      .enter()
      .append("tspan")
      .text((d) => d.value);

    text.exit().remove();
  }

  _drawFooter() {
    this.footer.svg
      .append("g")
      .attr("class", "footer-legend")
      .selectAll("text")
      .data(this.footer.data)
      .enter()
      .append("text")
      .attr("x", (d) => this.footer.scale.x(d.value))
      .attr("y", (d) => this.footer.dimensions.chart.height / 2)
      .selectAll("tspan")
      .data((d) => [
        { type: "value", text: d.value },
        { type: "label", text: d.label },
      ])
      .enter()
      .append("tspan")
      .attr("class", (d) => d.type)
      .attr("dx", (d) => (d.type == "label" ? 5 : 0))
      .text((d) => d.text);
  }
}
